<template>
  <div id="contact-container">
    <Head />
    <div class="content" v-html="data.content"></div>
  </div>
</template>

<script>
import Head from "@/components/head";
export default {
  data() {
    return {
      id: 9,
      type: 1,
      data: {}
    };
  },
  components: {
    Head,
  },
  computed: {},
  methods: {
    getArticleDetail() {
      this.$api.articleDetail({id: this.id, type: this.type}).then((res) => {
        if (res.data.status) {
          this.data = res.data.data;
        }
      });
    }
  },
  created() {
    this.getArticleDetail();
  },
  mounted() {},
};
</script>

<style lang="scss">
#contact-container {
  .content {
    width: 100%;
    overflow: hidden;
    margin-top: 140px;
    height: calc(100vh - 140px);
    img {
      margin-left: -25% !important;
      width: 150% !important;
    }
  }
}
</style>