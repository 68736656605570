<template>
  <div class="head">
    <div class="head-top">
      <div class="head-logo" @click="goHome">
        <img src="@/assets/logo.png" />
      </div>
      <div class="head-icon">
        <van-icon
          v-if="showIcon"
          name="search"
          size="1.4rem"
          style="margin-right: 5px"
          @click="search"
        />
        <van-icon name="wap-nav" @click="headShow" />
      </div>
    </div>
    <van-popup
      :show="show"
      position="left"
      :overlay="false"
      :style="{
        height: '100%',
        width: '46%',
        backgroundColor: 'rgba(153, 153, 153, 0.7)',
      }"
    >
      <ul class="head-ul">
        <li
          :class="active == 'about' ? 'active' : ''"
          @click="goRoute('/about')"
        >
          about
        </li>
        <li
          :class="active == 'process' ? 'active' : ''"
          @click="goRoute('/process')"
        >
        concept
        </li>
        <li :class="active == 'products' ? 'active' : ''">
          products
          <p
            :class="activeSub == 'furniture' ? 'active' : ''"
            @click="goRoute('/products/furniture')"
          >
            furniture
          </p>
          <p
            :class="activeSub == 'clothing' ? 'active' : ''"
            @click="goRoute('/products/clothing')"
          >
            clothing
          </p>
        </li>
        <li
          :class="activeSub == 'collaboration' ? 'active' : ''"
          @click="goRoute('/products/cooperation')"
        >
        collaboration
        </li>
        <li
          :class="active == 'project' ? 'active' : ''"
          @click="goRoute('/project')"
        >
          project
        </li>
        <li
          :class="active == 'catalog' ? 'active' : ''"
          @click="goRoute('/catalog')"
        >
          catalog
        </li>
        <li
          :class="active == 'contact' ? 'active' : ''"
          @click="goRoute('/contact')"
        >
          contact
        </li>
        <!-- <li
          :class="active == 'interior' ? 'active' : ''"
          @click="goRoute('/interior')"
        >
          interior
        </li> -->
      </ul>
    </van-popup>
  </div>
</template>

<script>
export default {
  name: "Head",
  props: {
    showIcon: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      show: false,
      link: [],
      active: "",
      activeSub: "",
      value: "",
    };
  },
  methods: {
    search() {
      this.$router.push({ path: "/search" });
    },
    goRoute(path) {
      if (path == "/products/furniture" || path == "/products/clothing" || path == "/products/cooperation") {
        this.show = false;
      }
      this.$router.push({ path: path });
    },
    headShow() {
      this.show = !this.show;
    },
    goHome() {
      this.$router.push({ path: "/" });
    },
    getMenuLink() {
      this.$api.menuLink().then((res) => {
        if (res.data.status) {
          this.link = res.data.data;
        }
      });
    },
  },
  created() {
    this.getMenuLink();
  },
  mounted() {
    let route = this.$route;
    this.active = route.matched[0].name;
    console.log(route);
    if (route.matched.length > 1) {
      this.activeSub = route.matched[1].name;
    }
  },
};
</script>

<style lang="scss" scoped>
.head {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100px;
  padding: 20px 0;
  z-index: 999999;
  .head-ul {
    text-align: left;
    text-indent: 60px;
    margin-top: 140px;
    li {
      line-height: 80px;
      font-size: 44px;
      color: #333;
      overflow: hidden;
      p {
        font-size: 38px;
        width: 100%;
        height: 80px;
        line-height: 80px;
        display: block;
        text-indent: 100px;
      }
      p.active,
      p:active {
        color: #000 !important;
        font-weight: bold;
      }
    }
    li.active,
    li:active {
      color: #000 !important;
      font-weight: bold;
    }
    li.active > p,
    li:active > p {
      color: #333;
      font-weight: 500;
    }
  }
  .head-top {
    height: 100px;
    .head-logo {
      float: left;
      height: 100px;
      line-height: 100px;
      img {
        display: inline-block;
        height: 100px;
      }
    }
    .head-icon {
      font-size: 60px;
      float: right;
      width: 210px;
      height: 100px;
      line-height: 100px;
    }
  }
}
</style>
